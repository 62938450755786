import $ from "jquery";

$(function() {
    $(".bl-team").on("click", ".item.expandable .itemcompact, .item.expandable .ctrexpandicon", function() {
        const $item = $(this).closest(".item");
        const $itemcompact = $item.find(".itemcompact");
        const $itemdetail = $item.find(".itemdetail");
        const animspeed = 200;

        const isexpanded = $item.hasClass("expanded");

        if(!isexpanded) {
            $item.addClass("expanded");
            $itemcompact.slideUp(animspeed);
            $itemdetail.slideDown(animspeed);
        } else {
            $item.removeClass("expanded");
            $itemcompact.slideDown(animspeed);
            $itemdetail.slideUp(animspeed);
        }
    });
});