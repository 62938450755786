import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

$(function() {
    $("a[href^='#']").on("click", function() {
        const $a = $(this);
        const anchor = $a.attr("href").substring(1);
        const $scrolltarget = $("a[name="+anchor+"]");

        const $header = $("header");
        const headerHeight = $header.outerHeight();

        $(window).scrollTo($scrolltarget, 1000, {
            offset: -headerHeight + 2 //avoid hairline bug
        });
    });
});

