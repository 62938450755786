import { ready } from "./utils.js";

function initSwiper() {
    const swiper = new Swiper('.swiper', {
      //loop: true,
      autoHeight: true,
      
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
    
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    
    });
}

ready(initSwiper);
